/* theme.less file that will be compiled */
@media print {
  * {
    text-shadow: none !important;
    color: #000 !important;
    background: transparent !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  select {
    background: #fff !important;
  }
  .navbar {
    display: none;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
  #portal-searchbox {
    display: None;
  }
  #portal-personaltools {
    display: None;
  }
  #portal-breadcrumbs {
    display: None;
  }
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 2px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  word-break: break-all;
  word-wrap: break-word;
  color: #4d4d4d;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}
table {
  max-width: 100%;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 300;
  color: #696969;
}
th {
  text-align: left;
  color: #4d4d4d;
}
.listing {
  width: 100%;
  margin-bottom: 20px;
}
.listing > thead > tr > th,
.listing > tbody > tr > th,
.listing > tfoot > tr > th,
.listing > thead > tr > td,
.listing > tbody > tr > td,
.listing > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 0 solid #e5e5e5;
  border-collapse: collapse;
}
.listing > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 1px solid #e5e5e5;
}
.listing > caption + thead > tr:first-child > th,
.listing > colgroup + thead > tr:first-child > th,
.listing > thead:first-child > tr:first-child > th,
.listing > caption + thead > tr:first-child > td,
.listing > colgroup + thead > tr:first-child > td,
.listing > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.listing > tbody + tbody {
  border-top: 2px solid #e5e5e5;
}
.listing .listing {
  background-color: #fafafa;
}
.listing > tbody > tr:nth-child(odd) > td,
.listing > tbody > tr:nth-child(odd) > th {
  background-color: #f6f6f6;
}
@media screen and (max-width: 767px) {
  .plone-has-table {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
  .plone-has-table:after {
    content: "⟷";
    position: fixed;
    color: #cccccc;
    margin-top: -3px;
  }
  .plone-has-table > .listing {
    margin-bottom: 0;
  }
  .plone-has-table > .listing > thead > tr > th,
  .plone-has-table > .listing > tbody > tr > th,
  .plone-has-table > .listing > tfoot > tr > th,
  .plone-has-table > .listing > thead > tr > td,
  .plone-has-table > .listing > tbody > tr > td,
  .plone-has-table > .listing > tfoot > tr > td {
    white-space: nowrap;
  }
}
table {
  width: 100%;
  max-width: 100%;
  border: 1px solid #e5e5e5;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #e5e5e5;
}
table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #e5e5e5;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
table > tbody + tbody {
  border-top: 2px solid #e5e5e5;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border: 1px solid #e5e5e5;
}
table > thead > tr > th,
table > thead > tr > td {
  border-bottom-color: #e5e5e5;
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #4d4d4d;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 100;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 6px;
  font-weight: bold;
}
input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}
input[type="file"] {
  display: block;
}
input[type="range"] {
  display: block;
  width: 100%;
}
select[multiple],
select[size] {
  height: auto;
  min-width: 10em;
}
select[size="1"] {
  height: 41px;
}
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #696969;
}
/*.form-control,*/
input[type="text"],
input[type="password"],
textarea,
select {
  display: block;
  width: 100%;
  height: 41px;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 27px;
  color: #696969;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border-color: #64bee8;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(100, 190, 232, 0.6);
}
input[type="text"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
textarea::-moz-placeholder,
select::-moz-placeholder {
  color: #777777;
  opacity: 1;
}
input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #777777;
}
input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #777777;
}
input[type="text"][disabled],
input[type="password"][disabled],
textarea[disabled],
select[disabled],
input[type="text"][readonly],
input[type="password"][readonly],
textarea[readonly],
select[readonly],
fieldset[disabled] input[type="text"],
fieldset[disabled] input[type="password"],
fieldset[disabled] textarea,
fieldset[disabled] select {
  cursor: not-allowed;
  background-color: #e5e5e5;
  opacity: 1;
}
textarea {
  min-height: 9em;
  min-width: 10em;
  resize: vertical;
}
textarea[name='form.widgets.IRichText.text'] {
  min-height: 12em;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
input[type="search"] {
  -webkit-appearance: none;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 41px;
}
input[type="radio"][disabled],
input[type="checkbox"][disabled],
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}
.field {
  margin-bottom: 15px;
}
.field span.option {
  display: block;
}
.field.error {
  background: #fbe8e4;
  -webkit-box-shadow: 0 0 0 5px #fbe8e4;
  box-shadow: 0 0 0 5px #fbe8e4;
}
.field.error .help-block,
.field.error .control-label,
.field.error .radio,
.field.error .checkbox,
.field.error .radio-inline,
.field.error .checkbox-inline {
  color: rgba(0, 0, 0, 0.5);
}
.field.error .form-control {
  border-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.field.error .form-control:focus {
  border-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(51, 51, 51, 0.5);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(51, 51, 51, 0.5);
}
.field.error .input-group-addon {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  background-color: #EC9C8D;
}
.field.error .form-control-feedback {
  color: rgba(0, 0, 0, 0.5);
}
.field.error > label,
.field.error div.error {
  color: #c4183c;
}
.field.error div.error {
  padding-left: 30px;
  font-weight: 300;
}
.field.error div.error:before {
  background-color: #ac493f;
  content: "×";
  font-size: 14px;
  font-weight: 300;
  margin-left: -30px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
  top: -1px;
  position: relative;
}
.field.error input,
.field.error textarea,
.field.error select {
  border-color: #c4183c;
}
.field.error input:focus,
.field.error textarea:focus,
.field.error select:focus {
  -webkit-box-shadow: 0 0 8px #f6b2c0;
  box-shadow: 0 0 8px #f6b2c0;
}
.formHelp {
  margin-top: 0;
  margin-bottom: 0;
  color: #666666;
  font-weight: 300;
  font-style: italic;
}
.formControls {
  border-top: 1px solid #cccccc;
  background: #fcfcfd;
  padding: 12px;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
.plone-modal-body .formControls {
  border: none;
  background: transparent;
  padding: 0;
  box-shadow: none;
}
label .required:after {
  color: #c4183c;
  content: "•";
  font-size: 200%;
  line-height: 0;
  position: relative;
  top: 7px;
  margin-left: -4px;
}
.searchField.portlet-search-gadget,
#sharing-user-group-search,
#select-rules {
  width: 100%;
  display: inline-block;
}
.searchField.portlet-search-gadget + .searchButton,
#sharing-user-group-search + #sharing-search-button,
#select-rules + input {
  position: relative;
  float: right;
  margin-top: -41px;
}
#search-results li {
  margin: 12px 0;
}
#search-results li .croppedDescription {
  margin-bottom: 0;
}
.highlightedSearchTerm {
  background: #fea;
  box-shadow: 0 0 1px 1px #fea;
}
.search-date-options > div,
.search-type-options > div {
  display: inline-block;
  margin-right: 12px;
}
.optionsToggle label {
  font-weight: 300;
  color: #696969;
}
.ordered-selection-field td {
  vertical-align: middle;
  text-align: center;
}
.ordered-selection-field td button {
  float: left;
  margin-left: 2rem;
}
.folder-factories {
  list-style: none;
}
.widget input + label {
  display: inline;
}
/*.standalone, .context, .destructive, [type="submit"], button {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @plone-font-weight-bold;
  text-align: center;
  text-shadow: 0 1px rgba(0,0,0,.25);
  vertical-align: middle;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@plone-padding-base-vertical; @plone-padding-base-horizontal; @plone-font-size-base; @plone-line-height-base; @plone-border-radius-small);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus {
    color: @plone-btn-standalone-color;
    text-decoration: none;
    .box-shadow(0 1px 2px rgba(0,0,0,.25));
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    pointer-events: none; // Future-proof disabling of clicks
    .opacity(.5);
    .box-shadow(none);
  }
}

.standalone, [type="submit"], button { //gray
  .button-variant(@plone-btn-standalone-color; @plone-btn-standalone-bg; @plone-btn-standalone-border);
}

.context { //blue
  .button-variant(@plone-btn-context-color; @plone-btn-context-bg; @plone-btn-context-border);
}

.destructive { //red
  .button-variant(@plone-btn-destructive-color; @plone-btn-destructive-bg; @plone-btn-destructive-border);
}

.link-parent {
  .standalone; margin-bottom: @plone-padding-base-horizontal;
  &:before {content: "↩ ";top: 3px;position: relative;}
}


// Base styles
// -------------------------
.input-group {
  position: relative; // For dropdowns
  display: table;
  border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

  // Undo padding and float of grid classes
  &[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }

  .form-control {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    position: relative;
    z-index: 2;

    // IE9 fubars the placeholder attribute in text inputs and the arrows on
    // select elements in input groups. To fix it, we float the input. Details:
    // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
    float: left;

    width: 100%;
    margin-bottom: 0;
  }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;

  &:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
}
// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  .border-right-radius(0);
}
.input-group-addon:first-child {
  border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  .border-left-radius(0);
}
.input-group-addon:last-child {
  border-left: 0;
}

// Button input groups
// -------------------------
.input-group-btn {
  position: relative;
  // Jankily prevent input button groups from wrapping with `white-space` and
  // `font-size` in combination with `inline-block` on buttons.
  font-size: 0;
  white-space: nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    position: relative;
    + .btn {
      margin-left: -1px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: 2;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      margin-right: -1px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      margin-left: -1px;
    }
  }
}
*/
.state-draft {
  color: #d39105;
}
.state-draft:hover,
.state-draft:active,
.state-draft:focus {
  color: #885e03;
}
.state-pending {
  color: #a5a912;
}
.state-pending:hover,
.state-pending:active,
.state-pending:focus {
  color: #62640b;
}
.state-private {
  color: #c4183c;
}
.state-private:hover,
.state-private:active,
.state-private:focus {
  color: #801027;
}
.state-internal {
  color: #d39105;
}
.state-internal:hover,
.state-internal:active,
.state-internal:focus {
  color: #885e03;
}
.state-internally_published {
  color: #7924f9;
}
.state-internally_published:hover,
.state-internally_published:active,
.state-internally_published:focus {
  color: #5405cc;
}
.plone-breadcrumb {
  clear: left;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: 0;
  color: #999999;
  /* uncomment to hide breadcrumbs on home
  body.section-front-page & {display: none;} //not breadcrumbs on homepage
  */
}
.plone-breadcrumb .container {
  width: 100% !important;
  padding: 0 !important;
}
.plone-breadcrumb a {
  color: #999999;
  word-break: break-all;
}
.plone-breadcrumb > * > span {
  display: inline;
  color: #999999;
  padding: 0;
  margin-right: 0.25em;
  position: static;
  left: 0;
  width: auto;
  height: auto;
  clip: auto;
  font-weight: 300;
}
@media (max-width: 768px) {
  .plone-breadcrumb > * > span {
    display: none;
  }
}
.plone-breadcrumb ol {
  display: inline;
  padding: 0;
  list-style: none;
  margin: 0;
}
.plone-breadcrumb ol > li {
  font-weight: 300;
  display: inline;
  line-height: 1.25em;
  position: relative;
  padding: 0 0 0 0;
}
.plone-breadcrumb ol > li + li:before {
  content: "/";
  padding: 0 5px;
  color: #999999;
}
.plone-breadcrumb ol > #breadcrumbs-current {
  color: #777777;
}
.listingBar {
  display: inline-block;
  padding: 0;
  list-style: none;
  margin: 20px 0;
}
.listingBar > li {
  font-weight: 300;
  display: inline;
  line-height: 0;
  position: relative;
  padding: 0;
  font-weight: 100;
}
.listingBar > li a {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #007bb3;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  margin-left: -1px;
}
.listingBar > li:first-child > a {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.listingBar > li:last-child > a {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.listingBar > li a:hover,
.listingBar > li a:focus {
  color: #004666;
  background-color: #f2f1f1;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.listingBar > .active > a,
.listingBar > .active > a:hover,
.listingBar > .active > a:focus {
  z-index: 2;
  font-weight: 300;
  color: #fff;
  background-color: #007bb3;
  border: #007bb3;
  cursor: default;
}
.listingBar > .disabled > a,
.listingBar > .disabled > a:hover,
.listingBar > .disabled > a:focus {
  color: #cccccc;
  background-color: #fcfcfd;
  border: 1px solid #e5e5e5;
  cursor: default;
}
@media (max-width: 480px) {
  .listingBar li {
    display: none;
  }
  .listingBar li.previous,
  .listingBar li.next {
    display: inline;
  }
}
.pat-autotoc.autotabs,
.autotabs {
  background: #fff;
  padding: 6px 12px;
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.pat-autotoc.autotabs .autotoc-nav,
.autotabs .autotoc-nav {
  background: #f2f1f1;
  border-bottom: 1px solid #e5e5e5;
  margin: -6px -12px 6px;
}
.pat-autotoc.autotabs .autotoc-nav a,
.autotabs .autotoc-nav a {
  display: inline-block;
  padding: 12px;
  margin: 6px 0 0 6px;
  border: 1px solid #e5e5e5;
  margin-bottom: -1px;
  border-radius: 4px 4px 0 0;
  background: #fcfcfd;
  font-weight: 300;
}
.pat-autotoc.autotabs .autotoc-nav a:last-child,
.autotabs .autotoc-nav a:last-child {
  margin-right: 6px;
}
.pat-autotoc.autotabs .autotoc-nav a:hover,
.autotabs .autotoc-nav a:hover {
  text-decoration: none;
  background: #fff;
}
.pat-autotoc.autotabs .autotoc-nav a.active,
.autotabs .autotoc-nav a.active,
.pat-autotoc.autotabs .autotoc-nav a.active:hover,
.autotabs .autotoc-nav a.active:hover {
  color: #696969;
  cursor: default;
  background: #fff;
  border-bottom-color: #fff;
}
.pat-autotoc.autotabs .autotoc-nav .autotab-heading,
.autotabs .autotoc-nav .autotab-heading {
  display: inline-block;
  margin: 6px 0 0 6px;
}
.pat-autotoc.autotabs .formControls,
.autotabs .formControls {
  margin: 0 -12px -6px;
  border-radius: 0 0 4px 4px;
}
@media (max-width: 767px) {
  .autotabs .autotoc-nav {
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 52px;
    width: auto;
    white-space: nowrap;
    border-bottom: 0;
    box-shadow: inset 0 -1px #e5e5e5;
  }
  .autotabs .autotoc-nav a:not(.active) {
    box-shadow: inset 0 -1px #e5e5e5;
  }
}
.autotabs ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.autotabs ul li {
  list-style: none;
  display: inline-block;
}
.folder-listing {
  font-weight: 700;
  list-style: none;
  position: relative;
  padding-left: 20px;
}
.folder-listing li {
  margin: 10px 0;
}
.folder-listing > li:before {
  content: "•";
  position: absolute;
  font-size: 19px;
  margin-top: -9px;
  left: 0;
  color: #a7daf2;
}
.folder-listing .documentByLine {
  font-weight: 300;
  color: #777777;
}
.tileImage {
  padding-right: 5px;
  float: left;
}
.tileImageNoFloat {
  margin-bottom: 5px;
}
.tileFooter {
  clear: both;
  margin-top: 5px;
}
.photoalbumEntryWrapper {
  display: block;
}
.photoAlbumEntry {
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}
.photoAlbumEntry a {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: 300;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background: #fcfcfd;
  width: 220px;
  height: 240px;
}
.photoAlbumEntry img {
  max-width: 200px;
  max-height: 200px;
}
.portalMessage {
  padding: 15px;
  padding-left: 45px;
  margin-bottom: 20px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  font-size: 14px;
  font-weight: 300;
  text-shadow: 0 1px rgba(255, 255, 255, 0.1);
}
.portalMessage > strong,
.portalMessage > dt {
  font-weight: 700;
  padding-right: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.portalMessage a {
  font-weight: 300;
  color: #005176;
}
.portalMessage > strong:before,
.portalMessage > dt:before {
  font-size: 14px;
  font-weight: 300;
  margin-left: -30px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.5);
  top: -1px;
  position: relative;
}
.portalMessage > dt,
.portalMessage > dd {
  display: inline;
}
.portalMessage > dd {
  margin: 0;
}
.portlet .portletContent > .portalMessage {
  padding: 15px;
  padding-left: 45px;
  margin: 15px;
}
.portalMessage.info {
  background-color: #aad6ea;
  border: 1px solid #9acee6;
  color: rgba(0, 0, 0, 0.5);
}
.portalMessage.info > strong,
.portalMessage.info > dt {
  color: rgba(0, 0, 0, 0.67);
}
.portalMessage.info > strong:before,
.portalMessage.info > dt:before {
  background-color: #3e7b91;
  content: "i";
}
.portalMessage.warning {
  background-color: #ebd380;
  border: 1px solid #e7ca66;
  color: rgba(0, 0, 0, 0.5);
}
.portalMessage.warning > strong,
.portalMessage.warning > dt {
  color: rgba(0, 0, 0, 0.67);
}
.portalMessage.warning > strong:before,
.portalMessage.warning > dt:before {
  background-color: #bc8d0d;
  content: "!";
}
.portalMessage.error {
  background-color: #EC9C8D;
  border: 1px solid #eb9484;
  color: rgba(0, 0, 0, 0.5);
}
.portalMessage.error > strong,
.portalMessage.error > dt {
  color: rgba(0, 0, 0, 0.67);
}
.portalMessage.error > strong:before,
.portalMessage.error > dt:before {
  background-color: #ac493f;
  content: "×";
}
/* special octogonal shape for error
.portalMessage.error {
	& > strong:before, & > dt:before {
		border-radius: 8px; //soften border
		background: @plone-alert-error-bullet;
		background:
			linear-gradient(135deg, transparent 4px, @plone-alert-error-bullet 0) top left,
			linear-gradient(225deg, transparent 4px, @plone-alert-error-bullet 0) top right,
			linear-gradient(315deg, transparent 4px, @plone-alert-error-bullet 0) bottom right,
			linear-gradient(45deg,  transparent 4px, @plone-alert-error-bullet 0) bottom left;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}
*/
.portlet {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.portlet > :first-child {
  border-radius: 4px 4px 0 0;
}
.portlet > :last-child {
  border-radius: 0 0 4px 4px;
}
.portlet .portletHeader {
  background: #f2f1f1;
  font-weight: normal;
  font-size: 18px;
  padding: 13px 15px;
  color: #696969;
}
.portlet .portletHeader > a {
  color: #333333;
}
.portlet.portletCalendar .portletHeader {
  text-align: center;
  position: relative;
}
.portlet.portletCalendar .portletHeader .calendarPrevious,
.portlet.portletCalendar .portletHeader .calendarNext {
  width: 30px;
  height: 30px;
  padding: 0 1px 2px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  left: 5px;
  font-size: 24px;
  margin-top: -3px;
  line-height: 28px;
  font-weight: 100;
}
.portlet.portletCalendar .portletHeader .calendarPrevious:hover,
.portlet.portletCalendar .portletHeader .calendarNext:hover {
  background: #fff;
  text-decoration: none;
}
.portlet.portletCalendar .portletHeader .calendarNext {
  right: 5px;
  left: inherit;
}
.portlet .portletContent {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-weight: 300;
}
.portlet .portletContent > *,
.portlet .portletContent > div:not(.portalMessage) {
  padding: 10px 15px;
  margin: 0;
  color: #696969;
}
.portlet .portletContent > ul {
  padding: 0;
  list-style: none;
  position: relative;
}
.portlet .portletContent > ul > li a {
  display: block;
  padding: 10px 15px 26px 30px;
  position: relative;
  z-index: 1;
  color: #333333;
}
.portlet .portletContent > ul > li > a {
  border-top: 1px solid #e5e5e5;
}
.portlet .portletContent > ul > li:first-child > a {
  border-top: 0;
}
.portlet .portletContent > ul > li a:before {
  content: "•";
  position: absolute;
  font-size: 25px;
  margin-top: -9px;
  left: 15px;
  color: #a7daf2;
}
.portlet .portletContent > ul ul {
  padding-left: 15px;
  list-style: none;
}
.portlet .portletContent > ul .portletItemDetails {
  display: block;
  z-index: 0;
  padding: 0 15px 10px 30px;
  font-weight: 300;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  color: #777777;
}
.portlet .portletContent > ul li:hover {
  background: #fcfcfd;
}
.portlet .portletContent > ul li:hover a:before {
  color: #64bee8;
}
.portlet.portletCalendar table {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  border-spacing: 0;
}
.portlet.portletCalendar table tr {
  line-height: 30px;
}
.portlet.portletCalendar table thead {
  background: #fcfcfd;
  border-bottom: 1px solid #e5e5e5;
}
.portlet.portletCalendar table thead th {
  color: #696969;
  font-weight: 100;
  height: 40px;
  text-align: center;
}
.portlet.portletCalendar table td > * {
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.portlet.portletCalendar table .cal_has_events {
  position: relative;
}
.portlet.portletCalendar table .cal_has_events a:hover {
  background: #dbecfe;
  text-decoration: none;
  box-shadow: 0 0 0 5px #dbecfe;
}
.portlet.portletCalendar table .cal_has_events a:after {
  content: "•";
  position: absolute;
  margin-left: 50%;
  left: -3px;
  font-size: 20px;
  color: #a7daf2;
  margin-top: 9px;
}
.portlet.portletCalendar table .cal_has_events a:hover:after {
  color: #64bee8;
}
.portlet.portletCalendar table .cal_next_month,
.portlet.portletCalendar table .cal_prev_month {
  color: #000;
}
.portlet.portletCalendar table .cal_next_month.cal_has_events > a,
.portlet.portletCalendar table .cal_prev_month.cal_has_events > a {
  color: #a7daf2;
}
.portlet.portletCalendar table .cal_next_month.cal_has_events > a:after,
.portlet.portletCalendar table .cal_prev_month.cal_has_events > a:after {
  color: #d3edf8;
}
.portlet.portletCalendar table .cal_next_month.cal_has_events > a:hover,
.portlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover {
  color: #004666;
}
.portlet.portletCalendar table .cal_next_month.cal_has_events > a:hover:after,
.portlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover:after {
  color: #64bee8;
}
.portlet.portletCalendar table .today > * {
  background: #e5e5e5;
  font-weight: 500;
  box-shadow: 0 0 0 5px #e5e5e5;
  color: #000;
}
.portlet.portletCalendar table tbody tr:first-child td > * {
  margin-top: 10px;
}
.portlet.portletCalendar table tbody tr:last-child td > * {
  margin-bottom: 10px;
}
.portlet .formControls {
  border: 0;
  background: none;
  padding: 0;
  box-shadow: none;
}
.portlet .portletFooter {
  border-top: 1px solid #e5e5e5;
  background: #fcfcfd;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
.portletNavigationTree nav.portletContent > ul > li a {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid #e5e5e5;
}
.portletNavigationTree nav.portletContent > ul > li strong {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid #e5e5e5;
  background: #fcfcfd;
  color: #696969;
  display: block;
  font-weight: 300;
}
.portletNavigationTree .portletContent {
  border-top: 0;
}
.portletNavigationTree .portletContent > ul > li > a {
  display: block;
  background: #edecec;
}
.portletNavigationTree .portletContent > ul > li > a:before {
  content: "";
}
.portletNavigationTree .portletContent > ul > li > a:hover {
  background: #fcfcfd;
}
.portletNavigationTree .portletContent > ul > li > a:hover:after {
  content: "⟩";
  position: absolute;
  right: 10px;
  font-size: 18px;
  margin-top: -3px;
  color: #777777;
}
.portletNavigationTree .portletContent > ul > li > ul a {
  padding-left: 40px;
}
.portletNavigationTree .portletContent > ul ul {
  padding-left: 0;
}
.portletNavigationTree .portletContent .navTreeCurrentNode > a {
  background: #fcfcfd;
  color: #696969;
}
.portletNavigationTree .portletContent .navTreeCurrentNode > a:after {
  content: "⟩";
  position: absolute;
  right: 10px;
  font-size: 18px;
  margin-top: -3px;
  color: #777777;
}
#upgrade-products .configlets li,
#install-products .configlets li,
#activated-products .configlets li,
#broken-products .configlets li {
  padding: 12px;
  border-top: 1px dotted #e5e5e5;
}
#upgrade-products .configlets li:first-child,
#install-products .configlets li:first-child,
#activated-products .configlets li:first-child,
#broken-products .configlets li:first-child {
  border-top: 0;
}
#upgrade-products .configlets h3,
#install-products .configlets h3,
#activated-products .configlets h3,
#broken-products .configlets h3,
#upgrade-products .configlets p,
#install-products .configlets p,
#activated-products .configlets p,
#broken-products .configlets p {
  margin: 0;
}
#upgrade-products .configlets .portletContent:last-child,
#install-products .configlets .portletContent:last-child,
#activated-products .configlets .portletContent:last-child,
#broken-products .configlets .portletContent:last-child {
  margin-bottom: 0;
}
#upgrade-products .configlets .pull-right,
#install-products .configlets .pull-right,
#activated-products .configlets .pull-right,
#broken-products .configlets .pull-right {
  margin: 0 0 12px 12px;
}
.switch-portlet-manager {
  margin-top: 0.5em;
  width: auto;
}
.managedPortlet {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}
.managedPortlet > :first-child {
  border-radius: 4px 4px 0 0;
}
.managedPortlet > :last-child {
  border-radius: 0 0 4px 4px;
}
.managedPortlet .portletHeader {
  background: #f2f1f1;
  font-weight: normal;
  font-size: 18px;
  padding: 13px 15px;
  color: #696969;
}
.managedPortlet .portletHeader > a {
  color: #333333;
}
.managedPortlet.portletCalendar .portletHeader {
  text-align: center;
  position: relative;
}
.managedPortlet.portletCalendar .portletHeader .calendarPrevious,
.managedPortlet.portletCalendar .portletHeader .calendarNext {
  width: 30px;
  height: 30px;
  padding: 0 1px 2px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  left: 5px;
  font-size: 24px;
  margin-top: -3px;
  line-height: 28px;
  font-weight: 100;
}
.managedPortlet.portletCalendar .portletHeader .calendarPrevious:hover,
.managedPortlet.portletCalendar .portletHeader .calendarNext:hover {
  background: #fff;
  text-decoration: none;
}
.managedPortlet.portletCalendar .portletHeader .calendarNext {
  right: 5px;
  left: inherit;
}
.managedPortlet .portletContent {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-weight: 300;
}
.managedPortlet .portletContent > *,
.managedPortlet .portletContent > div:not(.portalMessage) {
  padding: 10px 15px;
  margin: 0;
  color: #696969;
}
.managedPortlet .portletContent > ul {
  padding: 0;
  list-style: none;
  position: relative;
}
.managedPortlet .portletContent > ul > li a {
  display: block;
  padding: 10px 15px 26px 30px;
  position: relative;
  z-index: 1;
  color: #333333;
}
.managedPortlet .portletContent > ul > li > a {
  border-top: 1px solid #e5e5e5;
}
.managedPortlet .portletContent > ul > li:first-child > a {
  border-top: 0;
}
.managedPortlet .portletContent > ul > li a:before {
  content: "•";
  position: absolute;
  font-size: 25px;
  margin-top: -9px;
  left: 15px;
  color: #a7daf2;
}
.managedPortlet .portletContent > ul ul {
  padding-left: 15px;
  list-style: none;
}
.managedPortlet .portletContent > ul .portletItemDetails {
  display: block;
  z-index: 0;
  padding: 0 15px 10px 30px;
  font-weight: 300;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  color: #777777;
}
.managedPortlet .portletContent > ul li:hover {
  background: #fcfcfd;
}
.managedPortlet .portletContent > ul li:hover a:before {
  color: #64bee8;
}
.managedPortlet.portletCalendar table {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  border-spacing: 0;
}
.managedPortlet.portletCalendar table tr {
  line-height: 30px;
}
.managedPortlet.portletCalendar table thead {
  background: #fcfcfd;
  border-bottom: 1px solid #e5e5e5;
}
.managedPortlet.portletCalendar table thead th {
  color: #696969;
  font-weight: 100;
  height: 40px;
  text-align: center;
}
.managedPortlet.portletCalendar table td > * {
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.managedPortlet.portletCalendar table .cal_has_events {
  position: relative;
}
.managedPortlet.portletCalendar table .cal_has_events a:hover {
  background: #dbecfe;
  text-decoration: none;
  box-shadow: 0 0 0 5px #dbecfe;
}
.managedPortlet.portletCalendar table .cal_has_events a:after {
  content: "•";
  position: absolute;
  margin-left: 50%;
  left: -3px;
  font-size: 20px;
  color: #a7daf2;
  margin-top: 9px;
}
.managedPortlet.portletCalendar table .cal_has_events a:hover:after {
  color: #64bee8;
}
.managedPortlet.portletCalendar table .cal_next_month,
.managedPortlet.portletCalendar table .cal_prev_month {
  color: #000;
}
.managedPortlet.portletCalendar table .cal_next_month.cal_has_events > a,
.managedPortlet.portletCalendar table .cal_prev_month.cal_has_events > a {
  color: #a7daf2;
}
.managedPortlet.portletCalendar table .cal_next_month.cal_has_events > a:after,
.managedPortlet.portletCalendar table .cal_prev_month.cal_has_events > a:after {
  color: #d3edf8;
}
.managedPortlet.portletCalendar table .cal_next_month.cal_has_events > a:hover,
.managedPortlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover {
  color: #004666;
}
.managedPortlet.portletCalendar table .cal_next_month.cal_has_events > a:hover:after,
.managedPortlet.portletCalendar table .cal_prev_month.cal_has_events > a:hover:after {
  color: #64bee8;
}
.managedPortlet.portletCalendar table .today > * {
  background: #e5e5e5;
  font-weight: 500;
  box-shadow: 0 0 0 5px #e5e5e5;
  color: #000;
}
.managedPortlet.portletCalendar table tbody tr:first-child td > * {
  margin-top: 10px;
}
.managedPortlet.portletCalendar table tbody tr:last-child td > * {
  margin-bottom: 10px;
}
.managedPortlet .formControls {
  border: 0;
  background: none;
  padding: 0;
  box-shadow: none;
}
.managedPortlet .portletFooter {
  border-top: 1px solid #e5e5e5;
  background: #fcfcfd;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
.managedPortlet .portletHeader {
  font-weight: 300;
}
.managedPortlet.inheritedPortlet {
  margin: 5px 15px;
}
.managedPortlet.inheritedPortlet .portletHeader {
  padding: 3px 15px;
}
.managedPortlet.blockedPortlet {
  opacity: 0.7;
}
.managedPortletActions {
  float: right;
  margin-top: -6px;
}
.managedPortletActions form {
  display: inline-block;
}
.portlet.portletSiteSetup .portletContent > ul > li a:before {
  content: "";
}
.portlet.portletSiteSetup .portletContent > ul li:hover {
  background: #edecec;
}
.portletNavigationTree.portletSiteSetup nav.portletContent > ul > li a {
  border: none;
  padding: 0px;
}
.portletNavigationTree.portletSiteSetup a {
  border: none;
  text-align: center;
  height: 100%;
  padding: 0;
}
.portletNavigationTree.portletSiteSetup a span {
  font-size: 50px;
  text-align: center;
  display: block;
  color: #4d4d4d;
}
.portletNavigationTree.portletSiteSetup ul {
  background: #edecec;
  padding-bottom: 20px;
}
.portletNavigationTree.portletSiteSetup li {
  vertical-align: top;
}
.portletNavigationTree.portletSiteSetup ul > li > a:hover:after {
  content: "";
}
.portletNavigationTree.portletSiteSetup .inner-configlet {
  margin-top: 10px;
  padding-bottom: 10px;
}
.portletNavigationTree.portletSiteSetup .inner-configlet:hover {
  background: rgba(255, 255, 255, 0.67);
  border-radius: 5px;
}
.portletNavigationTree.portletSiteSetup .inner-configlet:hover span {
  color: #333;
}
.template-overview-controlpanel .portlet.portletSiteSetup .portletContent > ul > li {
  height: 120px;
}
#portlet-prefs ul ul {
  padding-top: 10px;
}
#portlet-prefs ul ul li {
  padding-top: 10px;
}
#portlet-prefs ul ul li a {
  text-align: inherit;
  padding-left: 40px;
}
#portlet-prefs ul ul li a:before {
  content: "•";
  position: absolute;
  font-size: 25px;
  margin-top: -9px;
  left: 15px;
  color: #a7daf2;
}
.template-usergroup-groupprefs .field,
.template-usergroup-userprefs .field,
.template-usergroup-controlpanel .field,
.template-member-registration .field {
  margin-top: 2em;
}
.template-usergroup-groupprefs .formControls,
.template-usergroup-userprefs .formControls,
.template-usergroup-controlpanel .formControls,
.template-member-registration .formControls {
  border-top: 0;
  box-shadow: none;
  margin-bottom: 1em;
}
.template-usergroup-groupprefs table.listing,
.template-usergroup-userprefs table.listing,
.template-usergroup-controlpanel table.listing,
.template-member-registration table.listing {
  margin-bottom: 0.5em;
}
.template-usergroup-groupprefs input.quickSearch,
.template-usergroup-userprefs input.quickSearch,
.template-usergroup-controlpanel input.quickSearch,
.template-member-registration input.quickSearch {
  margin-bottom: 0.5em;
}
.template-usergroup-groupprefs .listingCheckbox,
.template-usergroup-userprefs .listingCheckbox,
.template-usergroup-controlpanel .listingCheckbox,
.template-member-registration .listingCheckbox {
  vertical-align: middle;
  text-align: center;
}
.template-usergroup-groupprefs form,
.template-usergroup-userprefs form {
  overflow: scroll;
}
.link-category {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: #4d4d4d;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px;
  background-color: #e5e5e5;
}
.link-category[href]:hover,
.link-category[href]:focus {
  background-color: #cccccc;
}
a.link-category:hover,
a.link-category:focus {
  color: #007bb3;
  text-decoration: none;
  cursor: pointer;
}
.link-category:empty {
  display: none;
}
#category {
  margin-bottom: 12px;
}
#category ul {
  list-style: none;
  padding: 0;
  display: inline;
}
#category li {
  display: inline-block;
}
body#visual-portal-wrapper.pat-plone .outer-wrapper [class*="contenttype-"]:before {
  font-family: "Fontello";
  font-size: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  left: inherit;
  display: inline-block;
  color: inherit;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 6px;
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-folder:before,
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-formfolder:before {
  content: '\e801';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-document:before {
  content: '\e80e';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-file:before {
  content: '\e811';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-link:before {
  content: '\e806';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-image:before {
  content: '\e810';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-collection:before {
  content: '\e808';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-event:before {
  content: '\e809';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-news-item:before {
  content: '\e80f';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-announcement:before {
  content: '\e831';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-banner:before {
  content: '\e812';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-slider:before {
  content: '\e818';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-easyform:before {
  content: '\e816';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-icon:before {
  content: '\e83e';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-minisite:before {
  content: '\e839';
}
body#visual-portal-wrapper.pat-plone #edit-zone [class*="contenttype-"] {
  padding: 5px 15px 5px 50px;
}
body#visual-portal-wrapper.pat-plone #edit-zone [class*="contenttype-"]:after {
  font-family: "Fontello";
  font-size: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  left: 25px;
  display: inline-block;
  color: inherit;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 6px;
  position: absolute;
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-folder:after,
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-formfolder:after {
  content: '\e801';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-document:after {
  content: '\e80e';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-file:after {
  content: '\e811';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-link:after {
  content: '\e806';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-image:after {
  content: '\e810';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-collection:after {
  content: '\e808';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-event:after {
  content: '\e809';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-news-item:after {
  content: '\e80f';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-announcement:after {
  content: '\e831';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-banner:after {
  content: '\e812';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-slider:after {
  content: '\e818';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-easyform:after {
  content: '\e816';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-icon:after {
  content: '\e83e';
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-minisite:after {
  content: '\e839';
}
body#visual-portal-wrapper.pat-plone .outer-wrapper .contenttype-directorymap:before,
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-directorymap:after {
  content: '\F278';
  font-family: "pictello";
  font-style: normal;
  font-size: 100%;
  display: inline-block;
  color: inherit;
  width: 20px;
  height: 20px;
  text-align: center;
}
body#visual-portal-wrapper.pat-plone #edit-zone .contenttype-directorymap:after {
  left: 25px;
}
#textAdjust .decrease-text {
  font-size: 12px;
}
#textAdjust .normal-text {
  font-size: 14px;
}
#textAdjust .increase-text {
  font-size: 18px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav {
  float: right;
  min-width: 150px;
  background-color: #f2f1f1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.17);
  padding: 6px 12px;
  margin: 0 0 12px 12px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav a {
  display: block;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .active,
.pat-autotoc:not(.autotabs) .autotoc-nav .active:hover {
  color: #696969;
  cursor: default;
  text-decoration: none;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-1 {
  margin-left: 0;
  font-size: 18px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-2 {
  margin-left: 12px;
  font-size: 14px;
}
.pat-autotoc:not(.autotabs) .autotoc-nav .autotoc-level-3 {
  margin-left: 24px;
  font-size: 12px;
}
@media (max-width: 767px) {
  .pat-autotoc .autotoc-nav {
    float: none;
    margin: 0 0 20px 0;
  }
}
.template-folder_contents .pat-structure .itemRow.folder .title a:hover {
  text-decoration: underline;
}
.pat-dropzone .dz-notice {
  margin-bottom: 6px;
}
.pat-dropzone .dz-default {
  border-radius: 6px;
  background-color: #f2f1f1;
  border: 3px dotted #cccccc;
  margin-bottom: 20px;
  padding: 12px;
  color: #696969;
}
.plone-modal {
  display: none;
  overflow: auto;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  z-index: 1040;
  background: rgba(255, 255, 255, 0.5);
}
.plone-modal .plone-modal-dialog {
  width: 100%;
  position: relative;
}
.plone-modal .plone-modal-dialog .plone-modal-header {
  min-height: 25px;
  padding: 12px;
}
.plone-modal .plone-modal-dialog .plone-modal-header h3 {
  font-size: 18px;
  margin: 0;
}
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close {
  margin-top: -12px;
  float: right;
  font-size: 36px;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:hover,
.plone-modal .plone-modal-dialog .plone-modal-header .plone-modal-close:focus {
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
}
.plone-modal .plone-modal-dialog .plone-modal-body {
  padding: 12px;
}
.plone-modal .plone-modal-dialog .plone-modal-footer {
  border-top: 1px solid #cccccc;
  background: #fcfcfd;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
  padding: 12px;
  text-align: right;
}
.plone-modal .plone-modal-dialog .plone-modal-footer .pattern-modal-buttons input + input {
  margin-left: 12px;
}
.plone-modal .plone-modal-content {
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  border-radius: 4px;
  outline: none;
  background-clip: padding-box;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 0;
  padding-right: 0;
}
.plone-modal.fade {
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.plone-modal.fade.in {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
.plone-modal.fade .plone-modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  -moz-transition: -moz-transform 0.25s ease-out;
  -o-transition: -o-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
}
.plone-modal.fade.in .plone-modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.plone-modal.in {
  display: block !important;
}
.plone-modal-loading {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #428bca;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
  border-radius: 0.5em;
  width: 40em;
  height: 40px;
}
.plone-modal-open {
  padding-right: 0;
}
.plone-modal-open > *:not(.plone-modal-wrapper):not(.select2-drop):not(.mce-floatpanel) {
  filter: blur(1px);
}
.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper {
  float: left;
}
.pattern-pickadate-wrapper .pattern-pickadate-time-wrapper .picker__input {
  width: 180px;
}
.pattern-pickadate-wrapper .pattern-pickadate-date-wrapper .picker__holder {
  width: 300px;
}
.picker--time {
  min-width: 256px;
  max-width: 320px;
}
#content .picker--opened .picker__holder {
  opacity: 1;
}
.picker__holder {
  width: 180px;
  margin: -1px 0 0 0;
  border: 1px solid #ccc;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 300px;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
}
.picker__wrap {
  margin: 0;
  /* overrides -1px used to get rid of unecessary scrollbar */
}
.picker__list-item:hover {
  background: #f0f0f0;
  color: #4d4d4d;
}
.picker__list-item--highlighted:hover,
.picker--focused .picker__list-item--highlighted {
  background: #007bb3;
  color: #fff;
}
li.picker__list-item {
  padding: 6px 12px;
  cursor: pointer;
  color: #696969;
}
ul.picker__list {
  background: #fff;
  list-style: none;
  margin: 0;
  padding: 0;
}
.picker--time .picker__button--clear {
  width: 100%;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}
.picker__table {
  width: 100%;
  text-align: center;
}
.picker__table th {
  text-align: center;
}
.picker__header {
  text-align: center;
  position: relative;
}
.picker__nav--next,
.picker__nav--prev {
  position: absolute;
  top: 0.5em;
}
.picker__select--month,
.picker__select--year {
  width: 35%;
  display: inline-block;
  margin: 0 6px;
  font-size: 1em;
}
.picker__day {
  font-weight: 400;
}
.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  cursor: pointer;
  background: #f0f0f0;
}
.picker__day--highlighted:hover,
.picker__day--highlighted {
  background: #007bb3;
  color: #fff;
  font-weight: 600;
}
.picker__day--outfocus {
  color: #cccccc;
}
.pat-sortable > *:hover {
  cursor: move;
  cursor: -webkit-grab;
  background: #FFF5CD;
}
.pat-sortable .item-dragging,
.pat-sortable .item-dragging:hover {
  cursor: -webkit-grabbing !important;
  background: #f2f2f2;
}
.item-dragging.dragging {
  background: #fea;
  cursor: -webkit-grabbing !important;
  padding: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
}
.pat-tablesorter th:hover {
  color: #007bb3;
}
.pat-tablesorter .sortdirection {
  font-size: 75%;
  opacity: 0.5;
  position: relative;
  top: -1px;
  padding: 2px;
  min-width: 15px;
  display: inline-block;
}
.tooltips {
  opacity: 0;
  transition: opacity 0.2s ease 0.5s;
  position: absolute;
  background: #333333;
  background: rgba(0, 0, 0, 0.9);
  color: #fafafa;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.17);
  max-width: 200px;
  margin-left: -100px;
  left: 200px;
  text-align: center;
  padding: 6px;
}
.tooltips.active {
  opacity: 1;
  transition: opacity 0.2s ease 0.5s;
}
.jqtree-tree {
  list-style: none outside;
  margin-bottom: 0;
  padding: 0;
  padding-left: 18px;
}
.jqtree-tree .jqtree-toggler {
  left: -18px;
  font-size: 75%;
  top: 30%;
  position: absolute;
}
.jqtree-tree .jqtree-element {
  padding: 6px;
}
.jqtree-tree .jqtree-element:hover {
  background: #f0f0f0;
}
.jqtree-tree .jqtree-selected > .jqtree-element {
  background: #cccccc;
}
#portal-header {
  margin: 0 auto 15px;
  padding-top: 15px;
  margin-bottom: 20px;
}
#portal-header #portal-logo {
  float: left;
}
#portal-header #portal-languageselector {
  float: left;
  clear: both;
  display: inline-block;
  margin-right: 29px;
  padding-top: 5px;
}
#portal-header #portal-languageselector li {
  display: inline-block;
  margin-right: 10px;
}
#portal-header #portal-anontools {
  float: right;
}
#portal-header #portal-anontools ul {
  padding-right: 0px;
}
#portal-header #portal-searchbox {
  clear: left;
  z-index: 2;
  white-space: nowrap;
}
#portal-header #portal-searchbox .searchSection {
  display: none;
}
#portal-header #portal-searchbox [type="text"] {
  width: 100%;
  display: inline-block;
}
#portal-header #portal-searchbox [type="submit"] {
  display: none;
}
#portal-header #portal-searchbox label {
  font-size: 86.667%;
}
#portal-header #portal-searchbox form > div {
  font-size: 0;
}
#portal-header #portal-searchbox form > div > * {
  font-size: 14px;
}
@media (min-width: 768px) {
  #portal-header #portal-languageselector {
    float: right;
    clear: none;
  }
  #portal-header #portal-anontools {
    float: right;
  }
  #portal-header #portal-searchbox {
    position: relative;
    padding-top: 0;
    clear: none;
    float: right;
    white-space: nowrap;
  }
  #portal-header #portal-searchbox .searchSection {
    display: inherit;
  }
  #portal-header #portal-searchbox [type="text"] {
    border-radius: 0;
    width: 100%;
    display: inline-block;
  }
  #portal-header #portal-searchbox [type="submit"] {
    display: none;
    width: 70px;
    display: inline-block;
    margin-left: -1px;
    vertical-align: top;
  }
  #portal-header #portal-searchbox label {
    font-size: 86.667%;
  }
  #portal-header #portal-searchbox form > div {
    font-size: 0;
  }
  #portal-header #portal-searchbox form > div > * {
    font-size: 14px;
  }
}
#portal-header:before,
#portal-header:after {
  content: " ";
  display: table;
}
#portal-header:after {
  clear: both;
}
.plone-nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.plone-nav > li {
  position: relative;
  display: block;
}
.plone-nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: white;
  font-size: 17px;
}
.plone-nav > li > a:hover,
.plone-nav > li > a:focus {
  text-decoration: none;
  background-color: #9abdd6;
}
.plone-navbar {
  background-color: #007bb3;
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
}
.plone-navbar:before,
.plone-navbar:after {
  content: " ";
  display: table;
}
.plone-navbar:after {
  clear: both;
}
.plone-navbar-header:before,
.plone-navbar-header:after {
  content: " ";
  display: table;
}
.plone-navbar-header:after {
  clear: both;
}
@media (min-width: 768px) {
  .plone-navbar-header {
    float: left;
  }
}
.plone-navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch;
  display: none;
}
.plone-navbar-collapse.in {
  overflow-y: auto;
  display: block;
}
@media (min-width: 768px) {
  .plone-navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .plone-navbar-collapse.plone-collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .plone-navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .plone-navbar-collapse,
  .navbar-static-top .plone-navbar-collapse,
  .navbar-fixed-bottom .plone-navbar-collapse {
    padding-left: 0;
    padding-right: 0;
  }
}
.plone-navbar-toggle {
  z-index: 1;
  position: relative;
  float: right;
  margin-top: 6px;
  margin-bottom: 3px;
  padding: 6px 10px;
  background-color: transparent;
  background-image: none;
  border: 1px solid white;
  border-radius: 4px;
  /*
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background: white;
    margin-top: 2px;
  }
  .icon-bar + .icon-bar {
    margin-top: 5px;
  }
  .icon-bar + .icon-bar + .icon-bar {
    margin-bottom: 3px;
  }
  //end burgerbar icon-bar
  */
}
.plone-navbar-toggle:focus {
  outline: 0;
}
.plone-navbar-toggle:hover {
  background-color: #6499c0;
  border-color: #4581ab;
}
.plone-navbar-toggle .icon-bar:after {
  content: "Menu";
  position: relative;
  color: white;
}
.plone-navbar-toggle .icon-bar + .icon-bar:after {
  content: "";
}
@media (min-width: 768px) {
  .plone-navbar-toggle {
    display: none;
  }
}
.plone-navbar-nav {
  margin: -1px -15px -1px;
}
.plone-navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
.plone-navbar-nav > .selected > a,
.plone-navbar-nav > .selected > a:hover,
.plone-navbar-nav > .selected > a:focus {
  color: white;
  background-color: #9abdd6;
}
@media (min-width: 768px) {
  .plone-navbar-nav {
    float: left;
  }
  .plone-navbar-nav > li {
    float: left;
  }
  .plone-navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .plone-navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
}
.principal {
  background: #d9d7d7;
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.1) 14%, transparent 14%, transparent 50%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.1) 64%, transparent 64%, transparent);
  background-size: 20px 20px;
  margin-top: -20px;
  margin-bottom: 20px;
  padding: 50px 0;
}
.principal .container-fluid {
  margin: 0 auto;
  width: 960px;
}
.gigantic h1 {
  font-size: 500%;
}
.gigantic p {
  font-size: 150%;
}
#content {
  margin-bottom: 72px;
}
.container,
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 768px) {
  .plone-toolbar-top-default .container {
    width: 750px;
  }
}
@media (min-width: 828px) {
  .plone-toolbar-left-default .container {
    width: 750px;
  }
}
@media (min-width: 888px) {
  .plone-toolbar-left-expanded .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .plone-toolbar-top-default .container {
    width: 970px;
  }
}
@media (min-width: 1052px) {
  .plone-toolbar-left-default .container {
    width: 970px;
  }
}
@media (min-width: 1112px) {
  .plone-toolbar-left-expanded .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .plone-toolbar-top-default .container {
    width: 1170px;
  }
}
@media (min-width: 1260px) {
  .plone-toolbar-left-default .container {
    width: 1170px;
  }
}
@media (min-width: 1320px) {
  .plone-toolbar-left-expanded .container {
    width: 1170px;
  }
}
#portal-footer-wrapper {
  text-align: center;
  clear: both;
}
#portal-footer-wrapper .portletWrapper {
  display: inline-block;
}
#portal-footer-wrapper .portletWrapper:first-child {
  display: block;
  padding-bottom: 1em;
  margin-bottom: 0.5em;
}
#portal-footer-wrapper .portlet {
  background: transparent;
  border: 0;
  font-weight: 100;
  box-shadow: none;
  margin-bottom: 0;
}
#portal-footer-wrapper .portlet .portletContent {
  padding: 0;
  background: transparent;
  border: 0;
}
#portal-footer-wrapper .portlet .portletContent > * {
  padding: inherit;
}
#portal-footer-wrapper .portlet .portletContent li {
  background: transparent;
  display: inline-block;
  border: 0;
  white-space: nowrap;
  padding: 10px 30px;
}
#portal-footer-wrapper .portlet#portal-colophon .portletContent a {
  padding: 10px 30px;
}
#portal-footer-wrapper .portlet .portletItem:before,
#portal-footer-wrapper .portlet .portletItem a:before {
  content: "";
}
#portal-footer-wrapper .portlet .portletItem a {
  padding: 0;
  border: 0;
}
#portal-footer-wrapper .portlet .portletActions {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-right: 15px;
}
#portal-siteactions {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
}
#portal-siteactions li {
  font-weight: bold;
  display: inline-block;
  margin-left: 30px;
}
#portal-siteactions li:first-child {
  margin-left: 0;
}
body.template-login_form #content-core,
body.template-logged_out #content-core {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  background: #fcfcfd;
  max-width: 300px;
  margin: 0 auto 6px;
  padding: 6px 12px;
}
body.template-login_form #content-core > :first-child,
body.template-logged_out #content-core > :first-child {
  border-radius: 4px 4px 0 0;
}
body.template-login_form #content-core > :last-child,
body.template-logged_out #content-core > :last-child {
  border-radius: 0 0 4px 4px;
}
body.template-login_form #content-core .portletHeader,
body.template-logged_out #content-core .portletHeader {
  background: #f2f1f1;
  font-weight: normal;
  font-size: 18px;
  padding: 13px 15px;
  color: #696969;
}
body.template-login_form #content-core .portletHeader > a,
body.template-logged_out #content-core .portletHeader > a {
  color: #333333;
}
body.template-login_form #content-core.portletCalendar .portletHeader,
body.template-logged_out #content-core.portletCalendar .portletHeader {
  text-align: center;
  position: relative;
}
body.template-login_form #content-core.portletCalendar .portletHeader .calendarPrevious,
body.template-logged_out #content-core.portletCalendar .portletHeader .calendarPrevious,
body.template-login_form #content-core.portletCalendar .portletHeader .calendarNext,
body.template-logged_out #content-core.portletCalendar .portletHeader .calendarNext {
  width: 30px;
  height: 30px;
  padding: 0 1px 2px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  left: 5px;
  font-size: 24px;
  margin-top: -3px;
  line-height: 28px;
  font-weight: 100;
}
body.template-login_form #content-core.portletCalendar .portletHeader .calendarPrevious:hover,
body.template-logged_out #content-core.portletCalendar .portletHeader .calendarPrevious:hover,
body.template-login_form #content-core.portletCalendar .portletHeader .calendarNext:hover,
body.template-logged_out #content-core.portletCalendar .portletHeader .calendarNext:hover {
  background: #fff;
  text-decoration: none;
}
body.template-login_form #content-core.portletCalendar .portletHeader .calendarNext,
body.template-logged_out #content-core.portletCalendar .portletHeader .calendarNext {
  right: 5px;
  left: inherit;
}
body.template-login_form #content-core .portletContent,
body.template-logged_out #content-core .portletContent {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-weight: 300;
}
body.template-login_form #content-core .portletContent > *,
body.template-logged_out #content-core .portletContent > *,
body.template-login_form #content-core .portletContent > div:not(.portalMessage),
body.template-logged_out #content-core .portletContent > div:not(.portalMessage) {
  padding: 10px 15px;
  margin: 0;
  color: #696969;
}
body.template-login_form #content-core .portletContent > ul,
body.template-logged_out #content-core .portletContent > ul {
  padding: 0;
  list-style: none;
  position: relative;
}
body.template-login_form #content-core .portletContent > ul > li a,
body.template-logged_out #content-core .portletContent > ul > li a {
  display: block;
  padding: 10px 15px 26px 30px;
  position: relative;
  z-index: 1;
  color: #333333;
}
body.template-login_form #content-core .portletContent > ul > li > a,
body.template-logged_out #content-core .portletContent > ul > li > a {
  border-top: 1px solid #e5e5e5;
}
body.template-login_form #content-core .portletContent > ul > li:first-child > a,
body.template-logged_out #content-core .portletContent > ul > li:first-child > a {
  border-top: 0;
}
body.template-login_form #content-core .portletContent > ul > li a:before,
body.template-logged_out #content-core .portletContent > ul > li a:before {
  content: "•";
  position: absolute;
  font-size: 25px;
  margin-top: -9px;
  left: 15px;
  color: #a7daf2;
}
body.template-login_form #content-core .portletContent > ul ul,
body.template-logged_out #content-core .portletContent > ul ul {
  padding-left: 15px;
  list-style: none;
}
body.template-login_form #content-core .portletContent > ul .portletItemDetails,
body.template-logged_out #content-core .portletContent > ul .portletItemDetails {
  display: block;
  z-index: 0;
  padding: 0 15px 10px 30px;
  font-weight: 300;
  position: relative;
  margin-top: -26px;
  font-size: 12px;
  color: #777777;
}
body.template-login_form #content-core .portletContent > ul li:hover,
body.template-logged_out #content-core .portletContent > ul li:hover {
  background: #fcfcfd;
}
body.template-login_form #content-core .portletContent > ul li:hover a:before,
body.template-logged_out #content-core .portletContent > ul li:hover a:before {
  color: #64bee8;
}
body.template-login_form #content-core.portletCalendar table,
body.template-logged_out #content-core.portletCalendar table {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  border-collapse: collapse;
  border-spacing: 0;
}
body.template-login_form #content-core.portletCalendar table tr,
body.template-logged_out #content-core.portletCalendar table tr {
  line-height: 30px;
}
body.template-login_form #content-core.portletCalendar table thead,
body.template-logged_out #content-core.portletCalendar table thead {
  background: #fcfcfd;
  border-bottom: 1px solid #e5e5e5;
}
body.template-login_form #content-core.portletCalendar table thead th,
body.template-logged_out #content-core.portletCalendar table thead th {
  color: #696969;
  font-weight: 100;
  height: 40px;
  text-align: center;
}
body.template-login_form #content-core.portletCalendar table td > *,
body.template-logged_out #content-core.portletCalendar table td > * {
  border-radius: 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
body.template-login_form #content-core.portletCalendar table .cal_has_events,
body.template-logged_out #content-core.portletCalendar table .cal_has_events {
  position: relative;
}
body.template-login_form #content-core.portletCalendar table .cal_has_events a:hover,
body.template-logged_out #content-core.portletCalendar table .cal_has_events a:hover {
  background: #dbecfe;
  text-decoration: none;
  box-shadow: 0 0 0 5px #dbecfe;
}
body.template-login_form #content-core.portletCalendar table .cal_has_events a:after,
body.template-logged_out #content-core.portletCalendar table .cal_has_events a:after {
  content: "•";
  position: absolute;
  margin-left: 50%;
  left: -3px;
  font-size: 20px;
  color: #a7daf2;
  margin-top: 9px;
}
body.template-login_form #content-core.portletCalendar table .cal_has_events a:hover:after,
body.template-logged_out #content-core.portletCalendar table .cal_has_events a:hover:after {
  color: #64bee8;
}
body.template-login_form #content-core.portletCalendar table .cal_next_month,
body.template-logged_out #content-core.portletCalendar table .cal_next_month,
body.template-login_form #content-core.portletCalendar table .cal_prev_month,
body.template-logged_out #content-core.portletCalendar table .cal_prev_month {
  color: #000;
}
body.template-login_form #content-core.portletCalendar table .cal_next_month.cal_has_events > a,
body.template-logged_out #content-core.portletCalendar table .cal_next_month.cal_has_events > a,
body.template-login_form #content-core.portletCalendar table .cal_prev_month.cal_has_events > a,
body.template-logged_out #content-core.portletCalendar table .cal_prev_month.cal_has_events > a {
  color: #a7daf2;
}
body.template-login_form #content-core.portletCalendar table .cal_next_month.cal_has_events > a:after,
body.template-logged_out #content-core.portletCalendar table .cal_next_month.cal_has_events > a:after,
body.template-login_form #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:after,
body.template-logged_out #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:after {
  color: #d3edf8;
}
body.template-login_form #content-core.portletCalendar table .cal_next_month.cal_has_events > a:hover,
body.template-logged_out #content-core.portletCalendar table .cal_next_month.cal_has_events > a:hover,
body.template-login_form #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:hover,
body.template-logged_out #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:hover {
  color: #004666;
}
body.template-login_form #content-core.portletCalendar table .cal_next_month.cal_has_events > a:hover:after,
body.template-logged_out #content-core.portletCalendar table .cal_next_month.cal_has_events > a:hover:after,
body.template-login_form #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:hover:after,
body.template-logged_out #content-core.portletCalendar table .cal_prev_month.cal_has_events > a:hover:after {
  color: #64bee8;
}
body.template-login_form #content-core.portletCalendar table .today > *,
body.template-logged_out #content-core.portletCalendar table .today > * {
  background: #e5e5e5;
  font-weight: 500;
  box-shadow: 0 0 0 5px #e5e5e5;
  color: #000;
}
body.template-login_form #content-core.portletCalendar table tbody tr:first-child td > *,
body.template-logged_out #content-core.portletCalendar table tbody tr:first-child td > * {
  margin-top: 10px;
}
body.template-login_form #content-core.portletCalendar table tbody tr:last-child td > *,
body.template-logged_out #content-core.portletCalendar table tbody tr:last-child td > * {
  margin-bottom: 10px;
}
body.template-login_form #content-core .formControls,
body.template-logged_out #content-core .formControls {
  border: 0;
  background: none;
  padding: 0;
  box-shadow: none;
}
body.template-login_form #content-core .portletFooter,
body.template-logged_out #content-core .portletFooter {
  border-top: 1px solid #e5e5e5;
  background: #fcfcfd;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 300;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}
#portal-anontools ul {
  text-align: right;
  list-style: none;
}
#portal-anontools li {
  margin: 12px 0 12px 12px;
}
#portal-sitemap {
  list-style: none;
  padding-left: 0;
}
#portal-sitemap > li {
  font-size: 18px;
  border-top: 1px solid #e5e5e5;
  padding: 6px 0 10px 0;
}
#portal-sitemap ul {
  position: relative;
  font-size: 14px;
  padding-left: 20px;
}
@media (min-width: 40em) {
  #portal-sitemap ul {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
  }
  #portal-sitemap ul ul {
    -moz-column-count: 1;
    -moz-column-gap: 0px;
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    column-count: 1;
    column-gap: 0px;
  }
  #portal-sitemap ul li {
    position: relative;
  }
}
.vevent span.cal_month {
  font-size: 1.1em;
}
.event.summary {
  font-weight: 300;
  max-width: 300px;
  float: right;
  background: #fafafa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
  padding: 12px 12px 12px 36px;
  margin-left: 5px;
}
.event.summary strong,
.event.summary li > span {
  display: block;
}
.event_listing article {
  border-top: 1px solid #e5e5e5;
  padding: 12px 0 12px;
}
.event_listing article h2 {
  margin: 0;
  padding: 12px 0 12px;
}
.event_listing article:first-child {
  border-top: 0;
  margin-top: -6px;
}
.cal_date {
  float: right;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
  text-align: center;
  width: 100px;
  margin: 12px 0 12px 12px;
  border-radius: 4px;
  overflow: hidden;
}
.cal_month {
  background: #00afff;
  font-weight: 300;
  display: block;
  color: #fff;
  padding: 6px 12px;
}
.cal_day {
  font-size: 36px;
  font-weight: 700;
  display: block;
}
.cal_wkday {
  font-size: 12px;
  font-weight: 100;
  display: block;
  padding: 0 12px 6px;
}
.event_listing .sub_nav {
  float: right;
}
.template-event_listing .caltrigger {
  display: none;
}
.autotabs .autotoc-nav .events-month-prev,
.autotabs .autotoc-nav .events-week-prev,
.autotabs .autotoc-nav .events-day-prev {
  position: relative;
  border-top-left-radius: 0;
  padding-left: 6px;
}
.autotabs .autotoc-nav .events-month-prev:before,
.autotabs .autotoc-nav .events-week-prev:before,
.autotabs .autotoc-nav .events-day-prev:before {
  content: "";
  position: absolute;
  padding: 0 5px;
  color: #e5e5e5;
  border-right: 5px solid;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  left: -6px;
  top: -1px;
  padding: 0 !important;
}
.autotabs .autotoc-nav .events-month-prev:after,
.autotabs .autotoc-nav .events-week-prev:after,
.autotabs .autotoc-nav .events-day-prev:after {
  content: "";
  position: absolute;
  padding: 0 5px;
  color: #fcfcfd;
  border-right: 5px solid;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  left: -5px;
  top: -1px;
  padding: 0 !important;
}
.autotabs .autotoc-nav .events-month-prev:hover:after,
.autotabs .autotoc-nav .events-week-prev:hover:after,
.autotabs .autotoc-nav .events-day-prev:hover:after {
  color: #fff;
}
.autotabs .autotoc-nav .events-month-next,
.autotabs .autotoc-nav .events-week-next,
.autotabs .autotoc-nav .events-day-next {
  position: relative;
  border-top-right-radius: 0;
  padding-right: 6px;
}
.autotabs .autotoc-nav .events-month-next:before,
.autotabs .autotoc-nav .events-week-next:before,
.autotabs .autotoc-nav .events-day-next:before {
  content: "";
  position: absolute;
  padding: 0 5px;
  color: #e5e5e5;
  border-left: 5px solid;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  right: -6px;
  top: -1px;
  padding: 0 !important;
}
.autotabs .autotoc-nav .events-month-next:after,
.autotabs .autotoc-nav .events-week-next:after,
.autotabs .autotoc-nav .events-day-next:after {
  content: "";
  position: absolute;
  padding: 0 5px;
  color: #fcfcfd;
  border-left: 5px solid;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  right: -5px;
  top: -1px;
  padding: 0 !important;
}
.autotabs .autotoc-nav .events-month-next:hover:after,
.autotabs .autotoc-nav .events-week-next:hover:after,
.autotabs .autotoc-nav .events-day-next:hover:after {
  color: #fff;
}
.image-product {
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  overflow: hidden;
  display: inline-block;
}
.image-product figcaption {
  font-size: 12px;
  background: #fcfcfd;
  margin: 6px 12px;
}
.portraitPhoto {
  margin: 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  overflow: hidden;
  display: inline-block;
  float: right !important;
  border-radius: 50px;
}
.portraitPhoto figcaption {
  font-size: 12px;
  background: #fcfcfd;
  margin: 6px 12px;
}
.newsImageContainer {
  float: right;
  margin: 0 0 6px 6px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.17);
  overflow: hidden;
}
.newsImageContainer a {
  display: table;
}
.newsImageContainer a img {
  display: block;
}
.newsImageContainer figcaption {
  display: table-caption;
  caption-side: bottom;
  font-size: 12px;
  background: #fcfcfd;
}
#commenting:before,
#commenting:after {
  content: " ";
  display: table;
}
#commenting:after {
  clear: both;
}
#commenting legend,
.discussion legend {
  padding: 0.5em 0.5em 0.5em 0;
  font-weight: normal;
}
.discussion:before,
.discussion:after {
  content: " ";
  display: table;
}
.discussion:after {
  clear: both;
}
.discussion .comment {
  margin-bottom: 12px;
  padding-left: 40px;
}
.discussion .commentImage {
  float: left !important;
  border-radius: 50px;
  overflow: hidden;
  position: absolute;
  margin-left: -40px;
  margin-top: 3px;
}
.discussion .documentByLine {
  font-weight: normal;
}
.discussion .commentDate {
  float: right !important;
  display: inline;
  font-size: 12px;
  color: #76797C;
}
.discussion .commentBody {
  margin: 1em 0;
  padding: 1em;
  overflow: auto;
  border: 1px solid #ccc;
}
.discussion .commentBody p {
  float: left;
  margin-bottom: 0;
}
.discussion .commentactionsform {
  display: inline;
  margin-left: 6px;
}
.discussion .commentActions {
  float: right;
  text-align: right;
}
.discussion .reply {
  border-bottom: 1px solid #e5e5e5;
}
.cancelreplytocomment {
  /* Hide the cancel button in the comment form, show it only in the reply form
   * in order to close it.
   */
  display: none;
}
.replyTreeLevel0 {
  margin-left: 0;
}
.replyTreeLevel1 {
  margin-left: 24px;
}
.replyTreeLevel2 {
  margin-left: 48px;
}
.replyTreeLevel3 {
  margin-left: 72px;
}
.replyTreeLevel4 {
  margin-left: 96px;
}
.replyTreeLevel5 {
  margin-left: 120px;
}
.replyTreeLevel6 {
  margin-left: 144px;
}
.replyTreeLevel7 {
  margin-left: 168px;
}
.replyTreeLevel8 {
  margin-left: 192px;
}
.replyTreeLevel9 {
  margin-left: 216px;
}
.replyTreeLevel10 {
  margin-left: 240px;
}
#viewlet-below-content .loginbutton {
  margin: 12px 0;
}
#viewlet-below-content .reply .loginbutton {
  display: none;
}
#viewlet-below-content .reply ~ .reply .loginbutton {
  display: inline-block;
}
#dobulkaction {
  margin: 0.3em 0;
}
.template-discussion-controlpanel .unclickable {
  opacity: 0.6;
  filter: alpha(opacity=60);
}
.searchPage {
  position: relative;
}
.searchPage .actionMenu.activated dd {
  display: block;
}
.searchPage .actionMenu {
  float: right;
}
.searchPage .actionMenu dt {
  top: 0;
  right: 0;
  position: absolute;
}
.searchPage .actionMenu dd {
  display: none;
  position: absolute;
  right: 0;
  top: 35px;
  width: 300px;
  background-color: #fcfcfd;
  border: 1px solid #e5e5e5;
  padding: 15px;
  z-index: 10;
}
#search-results-bar #results-count {
  float: right;
  padding-top: 15px;
  padding-right: 15px;
}
#searchform .input-group {
  max-width: 600px;
}
#searchform .input-group input.searchPage.btn {
  width: 120px;
}
#search-filter input {
  margin-right: 0.5rem;
}
/*
   Animation example, for spinners
*/
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@font-face {
  font-family: 'pictello';
  src: url('/++theme++ajpinedademar/fonts/pictello/pictello.eot?82357493');
  src: url('/++theme++ajpinedademar/fonts/pictello/pictello.eot?82357493#iefix') format('embedded-opentype'), url('/++theme++ajpinedademar/fonts/pictello/pictello.woff2?82357493') format('woff2'), url('/++theme++ajpinedademar/fonts/pictello/pictello.woff?82357493') format('woff'), url('/++theme++ajpinedademar/fonts/pictello/pictello.ttf?82357493') format('truetype'), url('/++theme++ajpinedademar/fonts/pictello/pictello.svg?82357493#pictello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'pictello';
    src: url('../font/pictello.svg?82357493#pictello') format('svg');
  }
}
*/
[class^="picto-"]:before,
[class*=" picto-"]:before {
  font-family: "pictello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.picto-rss:before {
  content: '\e800';
}
/* '' */
.picto-rss-alt:before {
  content: '\e801';
}
/* '' */
.picto-search:before {
  content: '\e802';
}
/* '' */
.picto-search-circled:before {
  content: '\e803';
}
/* '' */
.picto-search-1:before {
  content: '\e804';
}
/* '' */
.picto-down-open:before {
  content: '\e805';
}
/* '' */
.picto-left-open:before {
  content: '\e806';
}
/* '' */
.picto-right-open:before {
  content: '\e807';
}
/* '' */
.picto-up-open:before {
  content: '\e808';
}
/* '' */
.picto-down-open-mini:before {
  content: '\e809';
}
/* '' */
.picto-left-open-mini:before {
  content: '\e80a';
}
/* '' */
.picto-right-open-mini:before {
  content: '\e80b';
}
/* '' */
.picto-up-open-mini:before {
  content: '\e80c';
}
/* '' */
.picto-down-open-big:before {
  content: '\e80d';
}
/* '' */
.picto-left-open-big:before {
  content: '\e80e';
}
/* '' */
.picto-right-open-big:before {
  content: '\e80f';
}
/* '' */
.picto-up-open-big:before {
  content: '\e810';
}
/* '' */
.picto-down-open-1:before {
  content: '\e811';
}
/* '' */
.picto-left-open-1:before {
  content: '\e812';
}
/* '' */
.picto-right-open-1:before {
  content: '\e813';
}
/* '' */
.picto-up-open-1:before {
  content: '\e814';
}
/* '' */
.picto-link:before {
  content: '\e815';
}
/* '' */
.picto-chat:before {
  content: '\e816';
}
/* '' */
.picto-calendar:before {
  content: '\e817';
}
/* '' */
.picto-calendar-1:before {
  content: '\e818';
}
/* '' */
.picto-location:before {
  content: '\e819';
}
/* '' */
.picto-location-circled:before {
  content: '\e81a';
}
/* '' */
.picto-phone:before {
  content: '\e81b';
}
/* '' */
.picto-phone-circled:before {
  content: '\e81c';
}
/* '' */
.picto-mobile:before {
  content: '\e81d';
}
/* '' */
.picto-mail:before {
  content: '\e81e';
}
/* '' */
.picto-mail-circled:before {
  content: '\e81f';
}
/* '' */
.picto-home:before {
  content: '\e820';
}
/* '' */
.picto-home-1:before {
  content: '\e821';
}
/* '' */
.picto-comment-1:before {
  content: '\e822';
}
/* '' */
.picto-quote:before {
  content: '\e823';
}
/* '' */
.picto-quote-circled:before {
  content: '\e824';
}
/* '' */
.picto-ok:before {
  content: '\e825';
}
/* '' */
.picto-ok-circled:before {
  content: '\e826';
}
/* '' */
.picto-ok-circled2:before {
  content: '\e827';
}
/* '' */
.picto-cancel:before {
  content: '\e828';
}
/* '' */
.picto-cancel-circled:before {
  content: '\e829';
}
/* '' */
.picto-cancel-circled2:before {
  content: '\e82a';
}
/* '' */
.picto-cancel-squared:before {
  content: '\e82b';
}
/* '' */
.picto-plus-circled:before {
  content: '\e82c';
}
/* '' */
.picto-plus:before {
  content: '\e82d';
}
/* '' */
.picto-print:before {
  content: '\e82e';
}
/* '' */
.picto-help:before {
  content: '\e82f';
}
/* '' */
.picto-spin1:before {
  content: '\e830';
}
/* '' */
.picto-spin2:before {
  content: '\e831';
}
/* '' */
.picto-spin3:before {
  content: '\e832';
}
/* '' */
.picto-tools:before {
  content: '\e833';
}
/* '' */
.picto-spin4:before {
  content: '\e834';
}
/* '' */
.picto-traffic-cone:before {
  content: '\e835';
}
/* '' */
.picto-check:before {
  content: '\e836';
}
/* '' */
.picto-adjust:before {
  content: '\e837';
}
/* '' */
.picto-spin5:before {
  content: '\e838';
}
/* '' */
.picto-spin6:before {
  content: '\e839';
}
/* '' */
.picto-globe:before {
  content: '\e83a';
}
/* '' */
.picto-tag:before {
  content: '\e83b';
}
/* '' */
.picto-attention-circled:before {
  content: '\e83c';
}
/* '' */
.picto-wrench:before {
  content: '\e83d';
}
/* '' */
.picto-wrench-1:before {
  content: '\e83e';
}
/* '' */
.picto-thumbs:before {
  content: '\e83f';
}
/* '' */
.picto-up-1:before {
  content: '\e840';
}
/* '' */
.picto-clock:before {
  content: '\e841';
}
/* '' */
.picto-right:before {
  content: '\e842';
}
/* '' */
.picto-left:before {
  content: '\e843';
}
/* '' */
.picto-down:before {
  content: '\e844';
}
/* '' */
.picto-up-2:before {
  content: '\e845';
}
/* '' */
.picto-logout:before {
  content: '\e846';
}
/* '' */
.picto-thumbs-1:before {
  content: '\e847';
}
/* '' */
.picto-x:before {
  content: '\e879';
}
/* '' */
.picto-instagram-circled:before {
  content: '\f05e';
}
/* '' */
.picto-eye:before {
  content: '\f082';
}
/* '' */
.picto-link-ext:before {
  content: '\f08e';
}
/* '' */
.picto-menu:before {
  content: '\f0c9';
}
/* '' */
.picto-gplus-1:before {
  content: '\f0d5';
}
/* '' */
.picto-mail-alt:before {
  content: '\f0e0';
}
/* '' */
.picto-comment-empty:before {
  content: '\f0e5';
}
/* '' */
.picto-plus-squared:before {
  content: '\f0fe';
}
/* '' */
.picto-quote-left:before {
  content: '\f10d';
}
/* '' */
.picto-quote-right:before {
  content: '\f10e';
}
/* '' */
.picto-puzzle:before {
  content: '\f12e';
}
/* '' */
.picto-minus-squared:before {
  content: '\f146';
}
/* '' */
.picto-ok-squared:before {
  content: '\f14a';
}
/* '' */
.picto-link-ext-alt:before {
  content: '\f14c';
}
/* '' */
.picto-youtube-squared:before {
  content: '\f166';
}
/* '' */
.picto-youtube:before {
  content: '\f167';
}
/* '' */
.picto-instagram-1:before {
  content: '\f16d';
}
/* '' */
.picto-file-pdf:before {
  content: '\f1c1';
}
/* '' */
.picto-share:before {
  content: '\f1e0';
}
/* '' */
.picto-whatsapp:before {
  content: '\f232';
}
/* '' */
.picto-map-signs:before {
  content: '\f277';
}
/* '' */
.picto-map-o:before {
  content: '\f278';
}
/* '' */
.picto-wheelchair-fast:before {
  content: '\f29b';
}
/* '' */
.picto-user-circle:before {
  content: '\f2bd';
}
/* '' */
.picto-user-circle-o:before {
  content: '\f2be';
}
/* '' */
.picto-twitter:before {
  content: '\f309';
}
/* '' */
.picto-twitter-circled:before {
  content: '\f30a';
}
/* '' */
.picto-facebook:before {
  content: '\f30c';
}
/* '' */
.picto-facebook-circled:before {
  content: '\f30d';
}
/* '' */
.picto-pinterest:before {
  content: '\f312';
}
/* '' */
.picto-pinterest-circled:before {
  content: '\f313';
}
/* '' */
.picto-linkedin:before {
  content: '\f318';
}
/* '' */
.picto-linkedin-circled:before {
  content: '\f319';
}
/* '' */
.picto-instagram:before {
  content: '\f32d';
}
/* '' */
.picto-comment:before {
  content: '\f4ac';
}
/* '' */
.picto-calendar-2:before {
  content: '\f4c5';
}
/* '' */
/* our custom styles */
/*languages*/
.languages {
  position: relative;
  overflow: hidden;
  width: 170px;
  background-color: #f1f1f1;
}
.languages-icon {
  position: absolute;
  right: 8px;
  top: 7px;
  z-index: 0;
}
.languages select {
  cursor: pointer;
  position: relative;
  z-index: 1;
  background-color: transparent;
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  display: inline-block;
  font-size: 1.3em;
  margin: 0 !important;
  padding: 0.5em 1.5em 0.5em 0.5em;
  width: 188px;
}
.languages select:focus,
.languages select:active {
  background-color: transparent;
  border: none !important;
  box-shadow: none;
}
.languages select option {
  color: #444;
}
.grid img {
  width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
}
.plone-toolbar-left #edit-zone {
  z-index: 1000 !important;
}
/*# sourceMappingURL=++theme++ajpinedademar/styles/less/theme-compiled.css.map */